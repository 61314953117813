@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@font-face {
    font-family: "OscineTrialW01-Regular";
    src: url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.eot");
    /* IE9*/
    src: url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.woff2") format("woff2"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.woff") format("woff"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/08d1f8d7266491467febfa7a87bb7fe4.svg#OscineTrialW01-Regular") format("svg");
    /* iOS 4.1- */
}

.txtTo1 {
    font-family: Roboto;
    font-size: 29px;
    line-height: 40px;
    color: #122e99;
    padding-top: 45px;
    font-weight: 700;
}

.lb-admin-p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #353434;
}

.lb-admin-p.lta-tm {
    color: #8a8888;
}

.lb-admin-st {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 42px;
    color: #001c4e;
}

@media (max-width: 509px){
    .lb-admin-st {
        line-height: 28px;
    }
}

.c-b-ad {
    color: #2370c8;
}

.c-g-ad {
    color: #009c50;
    font-size: 22px;
}

@import "~@ng-select/ng-select/themes/default.theme.css";
.fondo-negro {
    background-color: rgba( 0, 0, 0, 0.4);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

.modal-ofera {
    margin: 12rem auto;
    max-width: 650px;
}

.pointer {
    cursor: pointer;
}


/* .swal2-popup .swal2-content{
    text-align: initial;
} */

.taxt-bold {
    font-weight: bold;
}


/*.card {
  box-shadow: 0 0 6px 4px rgb(0 0 0 / 5%);
  border-radius: 11px;
}*/

.card {
    margin-bottom: 30px;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 18%) !important;
    border-radius: 20px !important;
    border: 0px;
}

.btn-busqueda-admin {
    height: 38px;
    background: #fff;
    border: 1px solid #ced4da;
    border-left-width: 0;
    border-radius: 0 6px 6px 0;
    color: #009c50;
    font-size: 26px;
    padding: 0 0 0 8px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #009c50 transparent transparent;
    border-style: solid;
    border-width: 8px 8px 2.5px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #009c50;
    border-width: 0 8px 8px;
}

.ng-select.ng-select-single .ng-select-container .disabled:active {
    background-color: #e9ecef;
}

.ng-select.ng-select-single .ng-select-container {
    height: 40px;
}

.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center;
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 10px;
}

.ng-select.ng-select-disabled>.ng-select-container{
    background-color: #e9ecef;
    opacity: 1;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #999;
}

.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px;
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
    left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: #ebf5ff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 10px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f5faff;
    color: #333;
}

.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 5px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px;
}

.btn-blue-admin {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #fff;
    background: #2370c8;
    border: 11px;
    border-radius: 11px;
    height: 46px;
    width: 100%;
}

.btn-blue-admin:hover {
    -webkit-box-shadow: 0 14px 26px -12px rgba(0, 98, 152, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 98, 152, 0.2);
    box-shadow: 0 14px 26px -12px rgba(0, 98, 152, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 98, 152, 0.2);
}

.modal-admin {
    margin: 12rem auto;
    max-width: 650px;
}

.modal-dialog .modal-content .modal-header {
    background-color: #006198;
}

.heading {
    padding: .3rem;
    margin: 0;
    font-size: 1.15rem;
    color: #fff;
}

.white-text {
    color: #fff !important;
}

.form-control.activeErrorAdmin {
    border-color: #ef5350;
}

.brdtext-vc {
    border-bottom: 1px solid #001c4e;
    margin: 0 0 10px;
}

[type="radio"].radio-col-blue-admin:checked+label:after {
    background-color: #009c50;
    border-color: #009c50;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue-admin:checked+label:before {
    border: 2px solid #009c50;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue-admin:checked+label:after {
    background-color: #009c50;
    border: 2px solid #009c50;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 5px;
    background-color: #cae8ca;
    border: 2px solid #4CAF50;
}

.c-t-admin {
    position: -webkit-sticky;
    position: sticky;
    z-index: 19;
    top: 70px
}

#main-wrapper {
    overflow: inherit;
}

.p-a-n {
    color: #000000;
    font-family: Roboto;
    text-align: justify;
    margin-left: 0;
    padding: 0;
}

.alert.alert-danger {
    color: #ef5350;
    background-color: #fff;
    border-color: #fff;
}

.form-control.activeErrorAdmin {
    border-color: #ef5350;
}

.pd-evs {
    padding: 0;
}

lbl-rb {
    font-size: 13px;
    font-family: Roboto;
}

.errorCuestionario {
    background: #ff000014;
}

.btn-scs {
    cursor: pointer;
    border-radius: 30px;
    background: #2370c8;
    padding: 0 25px;
    height: 50px;
}

.topbar .top-navbar .navbar-nav>.nav-item.show {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
}

.pdg-eval {
    color: #000000;
    font-weight: bolder;
    padding: 1px;
}

@media (max-width: 1360px) {
    .pdg-eval {
        font-size: 15px;
    }
}

.lbl-dw-ej {
    font-size: 16px;
    color: #000000;
    padding: 4px;
}

.muy-alto {
    background: #ff3500;
    color: white;
    font-weight: bold;
}

.alto {
    background: #ff8000;
    color: white;
    font-weight: bold;
}

.medio {
    background: #fdf731;
    color: white;
    font-weight: bold;
}

.bajo {
    background: #16a53e;
    color: white;
    font-weight: bold;
}

.nulo {
    background: #00bff3;
    color: white;
    font-weight: bold;
}

.s-c-black{
    color: #000;
    font-weight: bold;
    cursor: pointer;
}